
import { Component, Prop } from 'vue-property-decorator';
import { inject } from '@/inversify';
import moment from 'moment';

import type ProvidersService from '@/modules/providers/providers.service';
import { KEY } from '@/inversify.keys'; import type DocumentFiltersService from '@/modules/document-filters/document-filters.service';
import type UserService from '@/modules/user/user.service';
import type HelperService from '@/modules/common/services/helper.service';
import type CompsetsService from '@/modules/compsets/compsets.service';
import type ClusterService from '@/modules/cluster/cluster.service';
import type HotelsService from '@/modules/hotels/hotels.service';

import ProviderCard from '@/modules/common/components/ui-kit/provider-card.vue';
import DayTooltipTemplate from '@/modules/common/components/ui-kit/day-tooltip-template.vue';
import formatDate from '@/modules/common/filters/format-date.filter';
import PRICE from '@/modules/common/modules/rates/constants/price.enum';
import HotelRooms from '@/modules/common/interfaces/hotelRooms.interface';
import type RatesService from '../rates.service';

import { FirstColumnType, TableRow } from '../interfaces/day-rate-tooltip.interface';
import RatesDocumentItemModel from '../models/rates-document-item.model';
import RatesAllService, { RatesAllServiceS } from '../rates-all.service';

const BOOKING_BASIC_ICON = require('@/modules/common/assets/booking-basic.svg');

@Component({
    filters: {
        Cut(value: string) {
            const countBreakpoint = 20;

            if (value.length > countBreakpoint) {
                return `${value.substring(0, countBreakpoint)}...`;
            }

            return value;
        },
    },
    components: {
        ProviderCard,
    },
    extends: DayTooltipTemplate,
})
export default class DayRateTooltip extends DayTooltipTemplate {
    @inject(KEY.RatesService) protected ratesService!: RatesService;
    @inject(RatesAllServiceS) private ratesAllService!: RatesAllService;
    @inject(KEY.HelperService) private helperService!: HelperService;
    @inject(KEY.UserService) protected userService!: UserService;
    @inject(KEY.DocumentFiltersService) private documentFilterService!: DocumentFiltersService;
    @inject(KEY.CompsetsService) protected compsetsService!: CompsetsService;
    @inject(KEY.ClusterService) private clusterService!: ClusterService;
    @inject(KEY.HotelsService) private hotelService!: HotelsService;
    @inject(KEY.ProvidersService) private providersService!: ProvidersService;

    @Prop({
        type: Array,
        default: () => [],
    })
    private ignoreHotels!: (string | number)[];

    firstColumns: FirstColumnType[] = [FirstColumnType.RANK];

    get bookingBasicIcon() {
        return BOOKING_BASIC_ICON;
    }

    get checkinDay() {
        const { data: document } = this.ratesService;
        const { day } = this;

        if (
            !document
            || !document.checkinDates
            || !document.checkinDates[day]
        ) return undefined;

        const checkinDay = document.checkinDates[day];

        if (!checkinDay) return undefined;

        return checkinDay;
    }

    get demandValue() {
        if (!this.checkinDay || !Number.isFinite(this.checkinDay.demand)) {
            return '';
        }
        return this.checkinDay.demand as number;
    }

    get otbValue() {
        if (!this.checkinDay || !Number.isFinite(this.checkinDay.occupancy)) {
            return '';
        }
        return this.checkinDay.occupancy as number;
    }

    get date() {
        const { day } = this;
        const { year, month } = this.documentFilterService;
        const d = new Date(year, month, day);
        return moment(d).format('MMM DD, YYYY');
    }

    get lastUpdatedDate() {
        const emptyDate = '--/--/--';
        const { day } = this;
        const { data: document } = this.ratesService;

        if (!day || !document) {
            return emptyDate;
        }

        const updateDate = this.ratesService.getUpdateDate(day);

        if (updateDate === null) {
            return emptyDate;
        }

        return formatDate(updateDate);
    }

    get compsetId() {
        const { compsetId } = this.documentFilterService;

        return compsetId;
    }

    get hotelId() {
        return this.userService.currentHotelId;
    }

    get hotelNames(): { [k: number]: string } {
        const { data: document } = this.ratesService;
        const { currentHotelId } = this.userService;
        const { competitors } = this.compsetsService;

        if (!document) {
            return {};
        }

        let competitorNames = {};

        if (competitors) {
            const entries = [...competitors, currentHotelId!].map(hotelId => [
                hotelId,
                this.hotelService.hotelNames[hotelId],
            ]);
            competitorNames = Object.fromEntries(entries);
        }

        return {
            ...document.hotelNames,
            ...competitorNames,
        };
    }

    get isAllChannelsMode() {
        return this.ratesService.isAllChannelsMode;
    }

    get roomList() {
        const { day } = this;
        const { data: document } = this.ratesService;
        const { priceShown } = this.documentFilterService;
        const { currentHotelId } = this.userService;

        const competitors = this.isAllChannelsMode
            ? this.compsetsService.currentCompset?.rateProviders || []
            : this.documentFilterService.competitors || [];

        const DEFAULT_ROOM = { roomName: '-' };
        let rooms: HotelRooms = {};

        if (!currentHotelId || !document || !day) return [];

        const competitivePrice = this.isAllChannelsMode
            ? this.ratesAllService.getAverageRoomsPrice(day)
            : this.ratesService.getCompsetPrice(day);

        const COMPETETIVE_ROOM = {
            isUserHotel: false,
            isMedian: true,
            hotelId: -1,
            hotelName: this.isAllChannelsMode
                ? this.$t('compsetRate.average').toString()
                : this.$t(`compsetRate.${this.compsetType}`).toString(),
            price: competitivePrice || 0,
            priceString: '-',
            name: '-',
            rank: null,
            legendColor: '',
        };

        const toTableRow = (hotelId: number | string) => {
            const correctedHotelId = +hotelId || hotelId;
            const room = rooms[correctedHotelId] || DEFAULT_ROOM as RatesDocumentItemModel;
            const { roomName: name } = room;

            const price: number | string = this.ratesService
                .getPrice(day, correctedHotelId) || 0;

            const hotelName = this.isAllChannelsMode
                ? this.providersService.getProviderLabel(String(hotelId))
                : this.hotelNames[+hotelId] || String(hotelId);

            const providers = this.isCheapestMode
                ? this.ratesService.getRoomProviders(day, +hotelId, priceShown!)
                : [];

            let pax = null;

            pax = this.isAllChannelsMode
                ? this.ratesAllService.getCheckinDay(day)?.[hotelId]?.pax
                : this.ratesService.getCheckinDay(day)?.hotels[+hotelId]?.pax;

            return {
                isUserHotel: +currentHotelId === +hotelId,
                isMedian: false,
                isBasic: room.isBasic,
                pax,
                hotelId,
                hotelName,
                price,
                priceString: String(price),
                name: price > -1 && !!price ? name : '-',
                rank: null as (number | null),
                legendColor: '',
                providers,
                diff: (competitivePrice && price && price > -1)
                    ? (price - competitivePrice) / competitivePrice
                    : 0,
            } as TableRow;
        };

        let roomList: TableRow[] = [];

        const getRoomsWithoutPriceCount = () => roomList
            .reduce((acc, el) => ((+el.price && +el.price !== PRICE.NA) ? acc : acc + 1), 0);

        const byDescending = (a: TableRow, b: TableRow) => +b.price - +a.price;

        const calculateRank = ({ price }: TableRow, index: number) => {
            roomList[index].rank = (price && price !== PRICE.NA)
                ? roomList.length - index - getRoomsWithoutPriceCount()
                : null;
        };

        const currencySymbol = this.helperService
            .currencySymbol(document.currency || '') || '';

        rooms = this.ratesService
            .getAllRooms(day);

        const hotels = this.isAllChannelsMode
            ? (competitors as string[])
            : [...competitors, currentHotelId];

        roomList = hotels
            .filter(hotelId => !this.ignoreHotels.includes(+hotelId || hotelId))
            .map(toTableRow);

        roomList
            .sort(byDescending)
            .forEach(calculateRank);

        if (this.isCheapestMode) {
            roomList = roomList
                .filter(room => +room.hotelId === +this.hotelId!
                    || (room.price && room.price !== PRICE.NA));
        }

        if (competitivePrice && !this.ignoreHotels.includes(COMPETETIVE_ROOM.hotelId)) {
            roomList.push(COMPETETIVE_ROOM);
            roomList.sort(byDescending);
        }

        roomList = this.mutateRoomData(roomList, COMPETETIVE_ROOM);

        const decoratePrice = (_: any, index: number) => {
            const room: TableRow = roomList[index];

            if (!room.isMedian) {
                const isNa = this.ratesService
                    .isNA(day, +room.hotelId || room.hotelId);

                const isSoldOut = this.ratesService
                    .isSoldOut(day, +room.hotelId || room.hotelId);

                if (isSoldOut) {
                    room.name = '-';
                }

                if (isNa) {
                    room.priceString = this.$tc('na');
                    return;
                }

                if (isSoldOut) {
                    room.priceString = room.pax
                        ? this.$tc('nog', 0, [room.pax])
                        : this.$tc('soldOut');
                    return;
                }
            }

            if (room.price) {
                room.priceString = this.isAllChannelsMode
                    ? currencySymbol + (+room.price).toFixed(2).replace(/(0+|\.|\.0+)$/, '')
                    : currencySymbol + (+room.price).toFixed(0);
            }
        };

        roomList
            .sort(byDescending)
            .forEach(decoratePrice);

        return roomList;
    }

    get compsetType() {
        if (!this.$route.name?.includes('hotel') && this.compsetId && this.hotelId) {
            const clusterCompset = this.clusterService
                .getCompset(this.compsetId, this.hotelId);
            return clusterCompset ? clusterCompset.type : null;
        }

        if (!this.compsetsService.currentCompset) {
            return null;
        }

        return this.compsetsService.currentCompset.type;
    }

    get isHaveData() {
        const { day } = this;

        return !this.ratesService.isNoData(day);
    }

    get isCheapestMode() {
        return this.ratesService.settings.provider === 'cheapest';
    }

    get isOutOfRange() {
        return this.ratesService.isOutOfRange();
    }

    mutateRoomData(_: TableRow[], __: TableRow): TableRow[] {
        return _;
    }
}
